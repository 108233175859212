import axios from "axios";

const app = {
  //BASE_URL:  'http://localhost:8080/api',
  // BASE_URL: 'https://apidev.lawforme.co.uk/api', //for dev
  BASE_URL: 'https://lfm-api.devservices.uk//api',  //for uat
  // BASE_URL: process.env.REACT_APP_API_BASE_URL + '/api',  //for Vercel UAT
  // BASE_URL:'https://api.lawforme.co.uk/api',  //for live
  // UPLOAD_URL:'https://dummyurl/uploads/',
  // PUBLIC_DIR:'https://dummyurl.co/',
  Path: 'https://lfm-api.devservices.uk//img/', //for dev
  // Path: process.env.REACT_APP_API_BASE_URL + '/img/', //for Vercel UAT
  MapAPI: "AIzaSyAkSdoMo4Ts1YTGny5fHAaXtdPmmlKx0tY",

};

app.dataType = 'json';
app.contentType = {
  urlencoded: "appliction/x-www-form-urlencoded",
  json: "application/json",
  formData: "multipart/form-data;",
  no: false
};

// uk time jone

app.ukTimeZone = () => {
  //return Date.now()
  return new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "Europe/London",
    })
  );
};

// var token = sessionStorage.getItem("token");


app.get = (url, run = function () { }, base = null) => {
  if (!base)
    url = app.BASE_URL + url;
  var token = sessionStorage.getItem("token");

  var config = {
    method: "get",
    url: `${url}`,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "cache-control": "no-cache",
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data)
        run(null, res.data);
      })
      .catch((err) => {
        reject(err);
        run(true, err);
      });
  })

}

app.post = (url, data, run, base = null) => {
  var token = sessionStorage.getItem("token");
  if (!base)
    url = app.BASE_URL + url;
  var config = {
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  })

}



// modified api res according me
app.Mypost = (url, data, run, base = null) => {
  var token = sessionStorage.getItem("token");
  if (!base)
    url = app.BASE_URL + url;
  var config = {
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {

    axios(config)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err);
      });
  })

}


app.postWithFile = (url, formData) => {
  var token = sessionStorage.getItem("token");
  url = app.BASE_URL + url;
  return new Promise((resolve, reject) => {
    axios.post(url, formData, {
      headers: {
        "Content-Type": app.contentType[app.dataType],
        "Authorization": "Bearer " + token
      },
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    });
  });
}

app.putWithFile = (url, formData) => {
  var token = sessionStorage.getItem("token");
  url = app.BASE_URL + url;
  return new Promise((resolve, reject) => {
    axios.put(url, formData, {
      headers: {
        "Content-Type": app.contentType[app.dataType],
        "Authorization": "Bearer " + token
      },
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
}

app.api = (url, type, data, base = null) => {
  var token = sessionStorage.getItem("token");
  if (!base)
    url = app.BASE_URL + url;
  var config = {
    method: type,
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  })

}



app.put = (url, data, run, base = null) => {
  var token = sessionStorage.getItem("token");
  if (!base)
    url = app.BASE_URL + url;
  var config = {
    method: "put",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  })



}

app.delete = (url, data, run, base = null) => {
  var token = sessionStorage.getItem("token");
  if (!base)
    url = app.BASE_URL + url;
  var config = {
    method: "delete",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "Authorization": "Bearer " + token
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err);
      });
  })



}






app.date = (d = null) => {
  var dt = null;
  if (!d)
    dt = new Date();
  else
    dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10)
    day = `0${day}`;
  if (month < 10)
    month = `0${month}`;

  return year + '-' + month + '-' + day;
};

app.myDate = (d = null) => {
  var dt = null;
  if (!d)
    dt = new Date()
  else
    dt = d

  const dateArray = dt.split(" ");


  return dateArray[2] + ' ' + dateArray[1] + ' ' + dateArray[3];
};

app.datetime = (d = null) => {
  var dt = null;
  if (!d)
    dt = new Date();
  else
    dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10)
    day = `0${day}`;
  if (month < 10)
    month = `0${month}`;

  var h = dt.getHours()
  var m = dt.getMinutes()
  var s = dt.getSeconds()

  return (`${year}-${month}-${day} ${h}:${m}:${s}`);
};

var id = 1;

app.getBookingId = (d = null) => {
  var dt = null;
  if (!d)
    dt = new Date();
  else
    dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10)
    day = `0${day}`;
  if (month < 10)
    month = `0${month}`;

  var h = dt.getHours()
  var m = dt.getMinutes()
  var s = dt.getSeconds()
  id++;
  var fullid = year + month + day + id;
  return fullid;
};

app.showToast = (msg = 'No message', level = 'default', time = 3000) => {
  let toaster_message = document.getElementById('toaster_message');
  toaster_message.innerHTML = msg;
  let toaster = document.getElementById('toaster');
  toaster.className = '';
  toaster.classList.add(level);
  toaster.classList.add('visible');
  setTimeout(() => { toaster.classList.remove('visible') }, time);
};

app.showMessage = (msg = 'No message', type = 'info') => {
  return (<div className={'alert alert-' + type} style={{ fontSize: '16px' }}>{msg}</div>)
};


app.init = () => {
  const css = '#toaster{align-items:center;border-radius:7px;min-width:300px;bottom:0;display:flex;min-height:50px;max-height:50px;justify-content:space-between;left:50%;padding:0 20px;position:fixed;transform:translateX(-50%) translateY(150%);transition:transform .35s ease;background-color:#323232}.visible{transform:translateX(-50%) translateY(-50%)!important;opacity:1!important}.success{background-color:#16a085!important}.danger{background-color:#c0392b!important}.warning{background-color:#f38211!important}#toaster span{color:#fff!important;font-size:13px;white-space:nowrap;letter-spacing:1.2px;font-family:Roboto,Helvetica,Arial,sans-serif}';
  const toaster_style = document.createElement("style");
  toaster_style.innerHTML = css;
  document.head.appendChild(toaster_style);

  const toaster = document.createElement("div");
  const toaster_message = document.createElement("span");
  toaster_message.id = 'toaster_message';
  toaster.id = 'toaster';
  toaster.appendChild(toaster_message);
  document.body.appendChild(toaster);
};


app.init();
app.print = (json) => {
  console.log(json);
  return JSON.stringify(json);
};


global.app = app;

export default app;

